import { monaco } from '@monaco-editor/react';
import format from 'xml-formatter';
//
monaco
    .init()
    .then(monaco => {
        //
        window.monaco = monaco;
        monaco.editor.EditorOptions.formatOnPaste = true;
        //
        // monaco.editor.createModel
        //
        monaco.languages.registerDocumentFormattingEditProvider('xml', {
            async provideDocumentFormattingEdits(model, options, token) {
                // const prettier = await import('prettier/standalone');
                // const xml = await import('@prettier/plugin-xml');
                // const text = prettier.format(model.getValue(), {
                //     parser: 'xml',
                //     plugins: [xml],
                //     // singleQuote: true,
                // });
                //
                return formatXml(model);
            },
        });
        //
        monaco.languages.registerDocumentRangeFormattingEditProvider('xml', {
            async provideDocumentRangeFormattingEdits(model, range, options, token) {
                return formatXml(model);
            },
        });
    })
    .catch(error => console.error('An error occurred during initialization of Monaco: ', error));
//
//
//
function formatXml(model, range) {
    let text = "";
    try {
        text = format(
            model.getValue(),
            {
                collapseContent: true,
                indentation: '\t',
                lineSeparator: '\n'
            });
    } catch (err) {
        text = model.getValue();
        alert(`An error occurred during formatting document: ${err}`);
        console.log('An error occurred during formatting document: ', err);
    }
    //
    return [
        {
            range: (range) ? range : model.getFullModelRange(),
            text,
        },
    ];
}